.nav-item.dropdown .dropdown-menu {
  width: 200px;
}

.avaitor {
  width: 200px;
}

.users-avaitor {
  width: 100px;
}

.page-header {
  background-color: azure;
}

.clicable {
  margin-bottom: 4px;
  transition: box-shadow 0.5s;
  cursor: pointer;
  border-left: 2px solid rgb(216, 217, 217) !important;
}

.clicable:hover {
  border-left: 2px solid green !important;
  ;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.nav-sub-tab {
  padding-left: 3.5rem;
}

.nav-sub-tab .nav-link {
  color: green;
}

.asset-card {
  cursor: pointer;
  transition: box-shadow 0.5s;
  border: 1px solid rgb(180, 197, 180) !important;
  border-left: 3px solid rgb(180, 197, 180) !important;
}

.asset-card:hover {
  border-left: 3px solid green !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.notification-bell {
  position: relative;
  display: inline-block;
}

.notification-count {
  position: absolute;
  top: -14px;
  right: -14px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 10px;
}

.transaction-card .clicable-text {
  font-weight: bolder;
  cursor: pointer;
  color: #1075e9;
}

.transaction-card .clicable-text:hover {
  color: #0b5ab5;
}

.transaction-card {
  transition: box-shadow 0.5s;
  border: 1px solid rgb(180, 197, 180) !important;
  border-left: 3px solid rgb(180, 197, 180) !important;
}

.isloading {
  height: 200px;
  width: 200px;
  background-image: url('./shared/assets/images/greenloading.gif');
  background-repeat: no-repeat;
}

.offcanvas-body .menu {
  background: transparent;
  border: 0;
  color: #ffffff;
}

.offcanvas-body .menu-item {
  font-size: 18px;
}

.offcanvas-body .menu-item-header {
  font-weight: bold;
  font-size: 22px;
  border-bottom: 1px solid #fff;
}

.offcanvas-body .menu-item:hover {
  background: rgb(240, 241, 240);
  color: rgb(14, 131, 14);
}

.edit-doc-remove-button {
  min-width: 92px;
}

.dashboard-chart-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 4px 10px;
}

.dashboard-chart-container .card {
  margin: 0 4px;
  padding: 0;
  background-color: azure;
}

.dashboard-chart-container .total-asset {
  font-size: 116px;
  color: rgb(136, 181, 123);
  font-weight: bold;
  cursor: pointer;
}

.dashboard-chart-container .total-asset:hover {
  color: rgb(75, 130, 60);
}

.dashboard-chart-container .list-group-item {
  background-color: transparent;
  border-right: none;
  border-left: none;
  padding-left: 0;
}

.dashboard-chart-container .list-group {
  border-radius: 0;
  margin: 0;
}

.dashboard-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

.dashboard-list-container .item-card {
  padding: 10px;
  text-align: center;
  color: #eff3f1;
  cursor: pointer;
}

.dashboard-list-container .list-card {
  margin: 5px;
}

.dashboard-list-container .list-card-title {
  color: #000d06;
  background-color: rgb(136, 181, 123);
  padding: 10px;
  margin: 0;
  font-weight: bold;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
}

.dashboard-list-container .card-body {
  max-height: 250px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-y: scroll;
}

.dashboard-list-container .list-group-item {
  border-right: none;
  border-left: none;
  border-radius: 0;
  background-color: azure;
}

.dashboard-list-container .list-group-item:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
}

.dashboard-list-container .list-group {
  border-radius: 0%;
}

.report-container .list-card-title {
  color: #000d06;
  padding: 10px;
  margin: 0.5px;
  font-weight: bold;
}

.report-container .list-card-body {
  margin: 0;
  padding: 0;
}

@keyframes refresh-icon-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .refreshing {
    animation: refresh-icon-spin infinite 1s linear;
  }
}

@media only screen and (max-width: 600px) {

  .dashboard-list-container .item-card,
  .list-card {
    width: 100%;
  }

  .dashboard-list-container .list-card {
    width: 100%;
  }
}

.text-with-ellipsis {
  max-height: 20px;
  overflow-x: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

@media screen {
  .noScreen {
    display: none !important;
  }
}

@media print {
  .noPrint {
    display: none !important;
  }
}

.print-font-size {
  font-size: 12px;
}

.printClass{
  font-size: 8px;
  font-weight: normal;
}

.noprintClass{
  font-size: 12px;
  font-weight: normal;
}

.login-image{
  background-image: url('./shared/assets/images/bg.png');
  height: 100vh !important;
}

